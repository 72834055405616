import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Contact = () => {
  return (
    <div className="subscreen">
        <center><h3>Contact us through one of the methods below:</h3>
        <div className="contact-info">
            <div className="contact-data">
                <div className="contact-icon">
                    <h2><FontAwesomeIcon icon="map-marker" /></h2>
                </div>
                <div className="contact-title"><h3>Address</h3></div>
                <div className="contact"><a href="http://maps.google.com/?q=48%20Wall%20Street%20,%2012th%20Floor,%20New%20York,%20NY%2010005&output=classic"> 48 Wall Street, 12th Floor, New York, NY 10005</a></div>
            </div>
            <div className="contact-data">
                <div className="contact-icon">
                    <h2><FontAwesomeIcon icon="phone" /></h2>
                </div>
                <div className="contact-title"><h3>Phone</h3></div>
                <div className="contact"><a href="tel:+12126519653">(212) 651-9653</a>;<a href="tel:+19177637196">(917) 763-7196</a></div>
            </div>
            <div className="contact-data">
                <div className="contact-icon">
                    <h2><FontAwesomeIcon icon="fax" /></h2>
                </div>
                <div className="contact-title"><h3>Fax</h3></div>
                <div className="contact">(212) 651-9654</div>
            </div>
            <div className="contact-data">
                <div className="contact-icon">
                    <h2><FontAwesomeIcon icon="envelope" /></h2>
                </div>
                <div className="contact-title"><h3>E-mail</h3></div>
                <div className="contact"><a href="mailto:rashida@tacticaltherapeutics.com"> rashida@tacticaltherapeutics.com</a></div>
            </div>
        </div></center>
    </div>
  )
}

export default Contact