import React from 'react'
import precto from '../../assets/summary1-precto.png'
import postcto from '../../assets/summary2-postcto.png'
import presurgery from '../../assets/summary3-presurgery.png'
import postsurgery from '../../assets/summary4-postsurgery.png'

const Summary = () => {
  return (
    <div className="subscreen">
        <div className="subscreen-title">
            <h2>Executive Summary</h2>
        </div>
        <div className="subscreen-body">
            <p>Tactical Therapeutics, Inc (“TTI”) a Delaware Corporation formed in 2005 in New York, has developed a patented lead clinical candidate Carboxyamidotriazole (CTO) to circumvent resistance and immunosuppression in the treatment of in several solid cancers and glioblastoma in adults and children.</p>

            <p>TTI is privately funded and uses a <u>Virtual Business Model</u> by outsourcing most of the drug development activities to top-tier contractors and service providers.</p>

            <h3>Founder & Management</h3>

            <p>Rashida Karmali, Ph. D, JD, MBA is Founder, CEO and Director of TTI has over 40 years of experience as a respected pioneer in cancer research, registered patent and business attorney and innovator in drug discovery and development.  TTI has a Part-time Management Team of experienced Life Sciences Financial Experts, Medical Officer, and Regulatory Consultants.</p>

            <p>TTI’s <u>Clinical & Scientific Advisors</u> bring years of experience and leadership in Oncology, Neuro-oncology, Medicinal Chemistry, Pharmaceutical Formulation and Sciences, and Pre-clinical Drug Development.</p>

            <h3>Lead & Mechanism of Action</h3>

            <p>CTO is administered orally, has safe toxicity profile, good bioavailability, and brain penetration. CTO inhibits Calcium signals linked to oncogenes, Calcium Channels linked to angiogenesis and cancer progression, and inflammatory cytokines linked to immunosuppression.</p>

            <h3>Funding</h3>

            <p>Raised $ 20 million from private and debt.</p>

            <h3>Intellectual Property</h3>

            <p>Strong, 8 US Patents, 75 International Patents-Composition of Matter, Process, Biomarker and Combination claim protection.</p>

            <h3>Milestones</h3>

            <h4>Completed Three Phase 1 trials</h4>

            <ul>
                <li>CTO Monotherapy in 44 Advanced Cancer Patients with different solid cancers: Showed Safety & Efficacy, <i> J. Cancer Therapy, 6: 322-333 (2015) </i> </li>
                <li>CTO + Temozolomide in 27 Recurrent GBM patients: Showed 1 complete and 6 partial responses, including resistant mutations, <i>J. Clin. Oncol. 36: 1702-8 (2018)</i> </li>
                <li>CTO + chemo-radiation and adjuvant Temozolomide in 15 newly diagnosed GBM patients (13/15 with unresectable tumors): Significantly improved Progression-free-survival (15 months) and Overall Survival (28 months not reached since 4 patients were still on CTO alone; 2 patients remain recurrence- free at 5 years), <i> J. Clin. Oncol. 36: 1702-8 (2018)</i></li>
            </ul>

            <h4>End of Phase 1 Meeting was held with the FDA Reviewers (November 2018)</h4>

            <p>Trial designs were discussed for: </p>

            <ul>
                <li>Randomized Phase 2 Trial in Recurrent GBM Patients</li>
                <li>Randomized Phase 2 trial in newly diagnosed GBM Patients</li>
            </ul>
        </div>
    </div>
  )
}

export default Summary