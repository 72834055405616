import React from 'react'
import brainscan1 from '../../assets/glioblastoma1-brainscan.png'
import brainscan2 from '../../assets/glioblastoma2-brainscan.png'
import modeofaction from '../../assets/glioblastoma-modeofaction.png'
import types from '../../assets/glioblastoma5-types.png'
import orphan from '../../assets/glioblastoma6-orphan.jpeg'

const Glioblastoma = () => {
  return (
    <div className="subscreen">
        <div className="subscreen-title">
            <h2>Glioblastoma</h2>
            <h2>Overview</h2>
            <div className="subscreen-row">
                <div className="subscreen-column">
                    <h4>Glioblastoma (grade IV astrocytoma) (GBM) is an aggressive primary brain tumor that is extremely difficult to treat.</h4>

                    <p>GBM grows rapidly and is invasive, and the malignant cells spread to nearby brain tissue, making complete surgical resection difficult.</p>

                    <p>Tumors contain more than one cell type, a characteristic that makes GBMs very difficult to treat. GBM exhibits aggressive spatial and molecular heterogeneity with multiple gene amplification signaling pathways.</p>

                    <h3>Mortality</h3>

                    <p>There is an unmet need for treatment, as the current prognosis for these patients is that only 8.7% survive for two years. Tumor-treating fields approved recently, adds 2-5 months to Overall Survival.</p>

                    <p>The therapeutic window for patients with recurrence is narrow, with off-label use of lomustine.</p>

                    <p>The average incidence of GBM in the United States and Europe is similar at approximately 3.5 cases per 100,000 individuals. </p>

                    <p>Annually ~ 23,000 adults in the US are diagnosed with primary malignant tumors of the brain and spinal cord.  GBM demonstrates the highest rate of incidence, 55-60% and qualifies for the Orphan indication ({"<"}200,000 or 1 in 1500 persons affected)</p>

                    <p><i>Sources: American Brain Tumor Association (<a href="http://www.abta.org">www.abta.org</a>); Brain and Other Nervous System Tumors. NCI SEER Database (<a href="http://www.seer.cancer.gov">www.seer.cancer.gov</a>)</i></p>
                </div>
                <div className="subscreen-column">
                    <img src={brainscan1} alt="Brain Scan" />
                    <img src={brainscan2} alt="Brain Scan" />
                </div>
            </div>

            <br /><br />
            <hr />

            <h2>
                Treatment Challenges
            </h2>

            <ul>
                <li>GBMs are highly resistant to common DNA-modifying agents, especially in GBM with unmethylated MGMT promoter (55%); about 45% have methylated MGMT promoter, which responds to Temozolomide, average survival is 12-18 months</li>
                <li>IDH-wild type and EGFR are difficult mutations to treat with survival of 11-15 months</li>
                <li>Malignant cells migrate into adjacent brain tissue, making complete resection difficult</li>
                <li>Current treatments often cause neurotoxicity</li>
                <li>Genomic mutations</li>
                <li>Drug Resistance</li>
                <li>Drug delivery through the BBB</li>
                <li>Angiogenesis</li>
                <li>Immunosuppression</li>
            </ul>
        </div>

        <br /><br />
        <hr />

        <h2>Our Product</h2>
        <h4><i>CTO Offers Promising Breakthrough Solutions in very sick GBM Patients in the Multicenter Phase IB Trial of CTO and Temozolomide in 27 Recurrent and 15 Newly Diagnosed GBM Patients by overcoming each of the above listed nine challenges, for example: </i></h4>

        <ul>
            <li>Addition of CTO to Chemo-radiation and adjuvant Temozolomide in 15 newly diagnosed GBM patients significantly improved the Overall survival to 28 months not reached, including responses in GBM with unmethylated MGMT. Two patients remain recurrence free for 5 years.</li>
            <li>Addition of CTO to Temozolomide in 27 recurrent GBM patients showed 1 complete response and 6 partial responses in GBM patients with IDH-wild type or EGFR mutations (ORR 26%).</li>
            <li>Addition of CTO (i.e., CAI) to Standard of Cancer therapy is expected to inhibit invasiveness of malignant cells into adjacent brain tissue by inhibiting Calcium channels STIM1 and ORAI1(CTO has anti-invasive and anti-metastatic effects)</li>
            <li>CTO showed a safe toxicity profile, and no neurotoxicity was observed when CTO was tested in 44 advanced cancer patients (Taylor et al, 2015) or in 42 GBM patients (Omuro et al, 2018).</li>
            <li>Genomic mutations- CTO inhibits non-voltage dependent Calcium signaling that modulates several receptor-mediated Calcium-dependent signaling pathways including EGFR, MEK, RAS, Akt, ERK, VEGF.</li>
            <li>Drug resistance-CTO inhibited HDAC, Wnt/B-catenin and BCr-Abl involved in drug resistance.</li>
            <li>Drug delivery through the BBB- CTO crossed the BBB and reached therapeutic levels in the brain tumor tissue (Omuro et al, 2018).</li>
            <li>Angiogenesis- Addition of CTO (i.e., CAI) is expected to inhibit angiogenesis by inhibiting production of VEGF and VEGF signaling in endothelial cells.</li>
            <li>Immunosuppression- Numerous cytokines (VEGF, GM-CSF, INF-ɤ, IL-2, IL-6, IL-8, MIP1-α, IL-1, TNF-α) were inhibited in three patients who showed durable Stable Disease in Phase I Study of CTO Monotherapy.</li>
        </ul>
        <center><img src={modeofaction} alt="CTO: Mode of Action" style={{width: "80%"}} /></center>
    </div>
  )
}

export default Glioblastoma