import { useEffect, useState } from 'react';
import './App.css';
import AppLogoColored from './assets/tactical-logo-colored.png'
import AppLogoWhite from './assets/tactical-logo-white.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Home from './screens/Home';
import { ScrollRestoration, useNavigate } from "react-router-dom";
import Summary from './screens/Technology/Summary';
import Glioblastoma from './screens/Technology/Glioblastoma';
import ClinicalTrials from './screens/Technology/ClinicalTrials';
import OurProduct from './screens/Technology/OurProduct';
import AboutUs from './screens/AboutUs';
import Contact from './screens/Contact';

function App({ screen, subScreen }) {
  const [ activeScreen, setActiveScreen ] = useState("");
  const [ activeSubScreen, setActiveSubScreen ] = useState("");
  const [ scroll, setScroll ] = useState("");
  const [ sidebar, setSidebar ] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setActiveScreen(screen);
  }, [screen]);

  useEffect(() => {
    setActiveSubScreen(subScreen);
  }, [subScreen])

  const listenScrollEvent = () => {
    if (window.scrollY < 70) {
      return setScroll("");
    } else if (window.scrollY > 70) {
      return setScroll(" scroll");
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);

    return () => window.removeEventListener('scroll', listenScrollEvent);
  }, []);

  const menuClick = () => {
    setSidebar((state) => state ? false : true);
  }

  return (
    <div className="App">
      <ScrollRestoration />
      <header className="App-header">
        <div className={`App-background${activeScreen === "Home" ? "" : "-theme-color"}`}></div>
        <div className={`App-pageTitle${activeScreen === "Home" ? "" : "-theme-color"}`}>
          {
            activeScreen === "Home"

            ?

            "Overcoming Resistance in Malignant Brain Cancer"
            :

            ""
          }
          {
            activeScreen === "Our Technology"

            ?

            "Our Technology"
            :

            ""
          }
          {
            activeScreen === "About us"

            ?

            "About us"

            :

            ""
          }
          {
            activeScreen === "Contact us"

            ?

            "Contact us"

            :

            ""
          }
        </div>
        <div className={`container${scroll}${sidebar ? " active" : ""}`}>
          <div onClick={() => menuClick()} className={`hamburger-menu${activeScreen === "Home" ? "" : " theme-color"}${scroll}`}>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div onClick={() => navigate('/')} className="App-logo">
            <img src={activeScreen === "Home" ? AppLogoColored : scroll === " scroll" ? AppLogoColored : AppLogoWhite} alt="Tactical Therapeutics Colored" />
          </div>
          <div className="hamburger-menu">
          </div>
          <menu className="App-sidebar">
            <ul className='menuList'>
              <div onClick={
                () => {
                  menuClick()
                }
              } className="closeButton">
                <FontAwesomeIcon icon={"xmark"} />
              </div>
              <li className='extraContainer'>
                <span onClick={
                    () => {
                      menuClick()
                      navigate('/technology')
                    }
                  }><FontAwesomeIcon icon={"microchip"} /> Our Technology</span>
                <ul className='extras'>
                  <li onClick={
                    () => {
                      menuClick()
                      navigate('/technology/summary')
                    }
                  }>Summary</li>
                  <li onClick={
                    () => {
                      menuClick()
                      navigate('/technology/glioblastoma')
                    }
                  }>Glioblastoma</li>
                  <li onClick={
                    () => {
                      menuClick()
                      navigate('/technology/clinicaltrials')
                    }
                  }>Clinical Trials</li>
                  <li onClick={
                    () => {
                      menuClick()
                      navigate('/technology/ourproduct')
                    }
                  }>Our Product</li>
                </ul>
              </li>
              <li onClick={
                    () => {
                      menuClick()
                      navigate('/aboutus')
                    }
                  }>
                <FontAwesomeIcon icon={"circle-info"} />
                About us
              </li>
              <li onClick={
                    () => {
                      menuClick()
                      navigate('/contact')
                    }
                  }>
                <FontAwesomeIcon icon={"message"} />
                Contact
              </li>
            </ul>
          </menu>
        </div>
      </header>

      {
        activeScreen === "Home"

        ?

        <Home />

        :

        ""
      }

      {
        activeScreen === "Our Technology"

        ?

        <div className='App-subscreen'>
          <div className="subscreen-menu">
            <ul>
              <li onClick={() => { navigate('/technology/summary') }} className={activeSubScreen === "Summary" ? "active" : ""}>Summary</li>
              <li onClick={() => { navigate('/technology/glioblastoma') }} className={activeSubScreen === "Glioblastoma" ? "active" : ""}>Glioblastoma</li>
              <li onClick={() => { navigate('/technology/clinicaltrials') }} className={activeSubScreen === "CTrials" ? "active" : ""}>Clinical Trials</li>
              <li onClick={() => { navigate('/technology/ourproduct') }} className={activeSubScreen === "Product" ? "active" : ""}>Our Product</li>
            </ul>
          </div>

          {
            activeSubScreen === "Summary"

            ?

            <Summary />

            :

            ""
          }

          {
            activeSubScreen === "Glioblastoma"

            ?
            
            <Glioblastoma />

            :

            ""
          }

          {
            activeSubScreen === "CTrials"

            ?

            <ClinicalTrials />

            :

            ""
          }

          {
            activeSubScreen === "Product"

            ?

            <OurProduct />

            : 

            ""
          }
          
        </div>

        :

        ""
      }

      {
        activeScreen === "About us"

        ?

        <AboutUs />

        : 
        
        ""
      }
      {
        activeScreen === "Contact us"

        ?

        <Contact />

        :

        ""
      }
      <footer style={activeScreen === "Our Technology" ? {padding: "0rem", paddingBottom: "1rem"} : {}} className='App-footer'>
        {
          activeScreen === "Our Technology"

          ?

          (
            <div style={{padding: "0rem", paddingBottom: "1rem"}} className="subscreen-menu">
              <ul>
                <li onClick={() => { navigate('/technology/summary') }} className={activeSubScreen === "Summary" ? "active" : ""}>Summary</li>
                <li onClick={() => { navigate('/technology/glioblastoma') }} className={activeSubScreen === "Glioblastoma" ? "active" : ""}>Glioblastoma</li>
                <li onClick={() => { navigate('/technology/clinicaltrials') }} className={activeSubScreen === "CTrials" ? "active" : ""}>Clinical Trials</li>
                <li onClick={() => { navigate('/technology/ourproduct') }} className={activeSubScreen === "Product" ? "active" : ""}>Our Product</li>
              </ul>
            </div>
          )

          :

          ""
        }
        
        COPYRIGHT © 2023. TACTICAL THERAPEUTICS, INC.<br />ALL RIGHTS RESERVED.
      </footer>
    </div>
  );
}

export default App;
