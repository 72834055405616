import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faXmark, faUser, faLightbulb, faChartLine, faMicrochip, faEnvelope, faPhone, faFax, faCircleInfo, faMessage, faMapMarker } from '@fortawesome/free-solid-svg-icons';
import {
  createHashRouter,
  RouterProvider,
} from "react-router-dom";

const router = createHashRouter([
  {
    path: "/",
    element: <App screen={"Home"} />,
  },
  {
    path: "/technology",
    element: <App screen={"Our Technology"} subScreen={"Summary"} />,
  },
  {
    path: "/technology/summary",
    element: <App screen={"Our Technology"} subScreen={"Summary"} />,
  },
  {
    path: "/technology/glioblastoma",
    element: <App screen={"Our Technology"} subScreen={"Glioblastoma"} />,
  },
  {
    path: "/technology/clinicaltrials",
    element: <App screen={"Our Technology"} subScreen={"CTrials"} />,
  },
  {
    path: "/technology/ourproduct",
    element: <App screen={"Our Technology"} subScreen={"Product"} />,
  },
  {
    path: "/aboutus",
    element: <App screen={"About us"} />
  },
  {
    path: "/contact",
    element: <App screen={"Contact us"} />
  }
]);

library.add(faXmark, faUser, faLightbulb, faChartLine, faMicrochip, faEnvelope, faPhone, faFax, faCircleInfo, faMessage, faMapMarker);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router} />
);
