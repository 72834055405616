import React, { useRef } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import ourproduct1 from '../../assets/ourproduct1.png'
import ourproduct2 from '../../assets/ourproduct2.png'
import ourproduct3 from '../../assets/ourproduct3.png'
import ourproduct4 from '../../assets/ourproduct4.png'
import ourproduct5 from '../../assets/ourproduct5.png'
import ourproduct6 from '../../assets/ourproduct6.png'
import ourproduct7 from '../../assets/ourproduct7.png'

const OurProduct = () => {
    const myRef = useRef(null);
    const executeScroll = () => myRef.current.scrollIntoView();

    return (
        <div className="subscreen" ref={myRef}>
            <div className="subscreen-title">
                <h2>Our Product</h2>
            </div>
            <Tabs onSelect={(index, last, e) => {
                setTimeout(() => {
                    executeScroll();
                }, 0);
            }}>
                <TabPanel>
                    <h2><b>CTO Overview</b></h2>
                    <h3>The Discovery</h3>
                    <p>While working as a patent attorney, Dr. Karmali had a client with a promising therapeutic for multiple cancerous indications. The client was interested in evaluating the therapy for prostate cancer and, due to her training in cancer research, Dr. Karmali decided to test the drug against a similar therapy, CAI-L651582, in development at Merck. During the studies, it was found that forming a salt of CAI and orotic acid would in fact improve CAI’s bioavailability and tolerability. The new compound produced was Orotate Salt of CAI-L651582. She patented, and eventually bought, the technology. She then founded TTI to bring this potent anti-cancer compound to patients in dire need of new brain cancer therapies. The chemical structure of orotate salt of L651582 was modified and the new chemical entity of CTO maintained the remarkable anticancer properties of CAI, but had drastically lower toxicity as compared to the original compound. CAI-L651582 was never approved by the FDA, as it was found to have unacceptable toxicity in as many as 16 clinical trials.</p>
                    <div className="divider"></div>
                    <h3>The Characteristics</h3>
                    <h4><b>CTO is a novel, orally administered, small molecule that inhibits angiogenesis and tumor cell proliferation by addressing multiple pathways, thereby circumventing resistance in brain cancer.</b></h4>
                    <div className="subscreen-row">
                        <div className="subscreen-column">
                            <ul>
                                <li>Carboxyamidotriazole orotate (CTO) is the Orotic Acid salt of 5-amino-1-(4-(4-chlorobenzoyl)-3,5-dichlorobenzyl)-1,2,3-triazole-4-carboxamide (CAI). TTI’s CAI is synthesized by a different process using different ingredients.<b>This resulted in formation of different cystals of New Molecular Entity and accounts for TTI’s CAI and CTO exhibiting a safer toxicity profile than CAI-L651582.</b></li>
                                <li>CAI is a non-voltage-gated (NVG) calcium signal inhibitor with anti-angiogenic, anti-proliferative, and anti-invasive properties that demonstrated promising efficacy in preclinical and clinical studies.</li>
                                <li>However, hydrophobic properties of CAI caused binding to plasma proteins and poor absorption, resulting in poor bioavailability and efficacy in vivo; CTO has increased bioavailability and reduced toxicity.</li>
                                <li>Orotic acid is a naturally occurring substance in cow’s milk that forms a stable complex with CAI.</li>
                                <li>CTO inhibits multiple oncokinases, including EGFR, MEK, RES, HDAC, HSP90, WNT/B-catenin, Akt, ERK, VEGF and Bcr-Abl. A pharmacodynamic biomarker for CTO has been developed.</li>
                                <li><b>CTO maintains the anticancer properties of CAI while improving upon CAI’s bioavailability and tolerability with the addition of orotic acid.</b></li>
                                <li>Calcium is a secondary messenger in cell regulation and function and in some diseases calcium signaling becomes abnormal. This creates for CTO a multi-targeted platform of inhibiting elevated calcium in several cellular events simultaneously.</li>
                            </ul>
                        </div>
                        <div className="subscreen-column">
                            <img src={ourproduct1} alt="CTO" />
                        </div>
                    </div>
                    <div className="divider"></div>
                    <h3>The Reaction to CTO</h3>
                    <h4>According to KOL primary research, CTO provides an opportunity to address the unmet need in improving survival with a favorable toxicity profile.</h4>
                    <div className="subscreen-row">
                        <div className="subscreen-column">
                            <ul>
                                <li>
                                    <b>Promising, Multi-targeted Mechanism of Action:</b> Interviewed KOLs viewed CTO’s multi-targeted mechanism of action favorably
                                </li>
                                <li>
                                    <b>Favorable Safety and Tolerability Profile:</b> KOLs viewed CTO’s safety profile as a key value driver differentiating the product from the various PI3-kinase inhibitors and anti-angiogenics in development
                                </li>
                                <li><b>Convenience of Oral Administration:</b> KOLs noted that an oral formulation was a key advantage for CTO, due to the rapid progression of glioblastoma and patient desire to minimize time spent in the hospital. <b>Based on the product profile presented, leading KOLs believe that CTO represents an attractive treatment alternative for refractory GBM patients.</b></li>
                                <li><b>Considering the tolerability and promising efficacy signal, targeting Calcium is an intriguing Advance in the care of high-grade gliomas (HGG) patients</b><i> Neuro-Oncology 20: 1152-1154, (2018), Schiff D, Purow B, Wick W, Gerstner E, Hegi M. <u>Comment</u> on J. Clin Oncology 36: 1702-1708 (2018), Omuro et al.</i></li>
                            </ul>
                        </div>
                        <div className="subscreen-column">
                            <img src={ourproduct2} alt="CTO KOL Reaction" />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <h2><b>Mechanism of Action</b></h2>
                    <h3>Key Factors addressed by CTO</h3>
                    <h4>The CTO mechanism is involved in non-voltage dependent calcium signaling, addressing several factors in malignant gliomas.</h4>
                    <ul>
                        <li>
                            <b>Angiogenesis:</b> Demonstrated reduction in exosome-stimulated angiogenesis and both VEGF expression and secretion
                        </li>
                        <li>
                            <b>Proliferation:</b> Reduction of proliferation, adhesion, motility and vascular tube formation
                        </li>
                        <li>
                            <b>Anti-Inflammation:</b> Inhibition of inflammation has been demonstrated, accompanied by reduction of reduction of TNF-α, IL-1β, IL-6, TGF-β, IL-8 (all increased in COVID-19). In Phase 1, in 3 cancer patients CTO inhibited VEGF, GM-CSF, INF-ɤ, IL-6, IL-8, MiPiα, IL-1β and TNF-α.
                        </li>
                        <li>
                            <b>Molecular Targeting:</b> Through non-voltage dependent calcium signaling, several transduction pathways are impacted, including multiple tyrosine kinase signaling pathways. Crosses the Blood Brain Barrier and reaches the CSF.
                        </li>
                        <li>
                            <b>Overcome Drug Resistance:</b> Augments apoptosis through inhibition of tyrosine phosphorylation of BCr-Abl, ERK, and Wnt-β-catenin, offering a plausible mechanism by which CTO circumvents chemoresistance to Temodar® and other drugs.
                        </li>
                    </ul>
                    <center>
                        <img src={ourproduct3} alt="Non-Voltage Dependent Calcium Signaling and Oncologic Pathways" />
                        <p><i>Source: Guo L, et al. Anti-Inflammatory and Analgesic Potency of Carboxyamidotriazole, a Tumorostatic Agent. JPET April 2008 vol. 325 no. 1 10-16; Corrado, et al. arboxyamidotriazole-Orotate Inhibits the Growth of Imatinib-Resistant Chronic Myeloid Leukaemia Cells and Modulates Exosomes-Stimulated Angiogenesis. PLoS One. 2012; 7(8): e42310; Sathornsumetee S, et al. Molecularly Targeted Therapy for Malignant Glioma. Cancer 2007(110) 13-24.</i></p>
                    </center>
                    <div className="divider"></div>
                    <p><b>Based on pharmacodynamic markers, CTO has a suggested mechanism of action that inhibits genes associated with non-voltage dependent calcium signaling, which ultimately translates into the inhibition of multiple oncogenic pathways.</b></p>
                    <p><u>Pharmacodynamic Transcriptional Markers of CTO Exposure in ex vivo Anagen Hair Assay</u></p>
                    <div className="subscreen-row">
                        <div className="subscreen-column">
                            <p>Assay Results:</p>
                            <ul>
                                <li>The transcriptional signatures associated with inhibition of EGFR, MEK, HDAC and HSP90 were strongly suppressed at all doses of CTO</li>
                                <li>Genes associated with non-voltage dependent calcium signaling were strongly suppressed, with RAS and Growth Factor Signature</li>
                                <li>Modest suppression of transcription signatures of WNT signaling was evident at longer exposure to CTO</li>
                                <li>In contrast, activation of tumor suppressor signatures associated with P53 was observed <b>Through non-voltage dependent calcium signaling, CTO impacts several transduction pathways, including multiple tyrosine kinase signaling pathways</b></li>
                            </ul>
                            <p>These results may provide the molecular MOA of CTO’s observed clinical benefit in a broad spectrum of tumors with different genomic types and a tool to design customized combination therapies of CTO with other agents.</p>
                            <p><i>Source: EORTC Abstract A233, October 19-23, 2013; 2013 ASCO Breakthrough Session: Abstract 115114</i></p>
                        </div>
                        <div className="subscreen-column">
                            <img src={ourproduct4} alt="Assay Graphs" />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <h2><b>Pre Clinical Results</b></h2>
                    <h4>Preclinical studies demonstrate that CAI, the active component in CTO, inhibits VEGF, Akt, and Erk activity.</h4>
                    <p><u>A murine glioblastoma xenograft model demonstrated that CTO in combination with Temodar significantly inhibits tumor growth.</u></p>
                    <div className="subscreen-row">
                        <div className="subscreen-column">
                            <h3>Study Purpose</h3>
                            <p>The primary purpose of the experiment was to evaluate the antitumor efficacy of CTO with or without Temodar in glioblastoma using a mouse xenograft model</p>
                            <h3>Methods</h3>
                            <ul>
                                <li>U251 human glioblastoma cells were subcutaneously implanted into male, athymic NCr-nu/nu mice.</li>
                                <li>Mice were treated with CTO and/or Temodar for two rounds on a Q1D x 5 schedule over a period of 76 days</li>
                            </ul>
                            <h3>Efficacy</h3>
                            <ul>
                                <li>Treatment with CTO resulted in a statistically significant decrease in tumor growth. Treatment with Temodar + CTO resulted in greater inhibition of tumor growth than either product alone. The antitumor activity of the combination treatment was greater than the additive activity of both compounds alone showing chemosynergy with Temodar + CTO</li>
                                <li>CTO demonstrated the ability to cross the Blood Brain Barrier in preclinical models and in GBM patients.</li>
                            </ul>
                            <p><i>Source: Karmali, RA., et al., Cancer Therapy 2011, 8:71-80.</i></p>
                        </div>
                        <div className="subscreen-column">
                            <img src={ourproduct5} alt="Preclinical Result Graphs" />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <h2>Intellectual Property</h2>
                    <h3>CTO Intellectual Property Overview</h3>
                    <h4>Tactical Therapeutics has an extensive IP estate, including the key patents lasting through 2037 listed below.</h4>
                    <img src={ourproduct6} alt="Intellectual Property" style={{width: "100%"}}/>
                </TabPanel>
                <TabPanel>
                    <h2>Pipeline</h2>
                    <img src={ourproduct7} alt="Intellectual Property" style={{width: "100%"}}/>
                    <p>SOC = Standard of Care</p>
                </TabPanel>
                <TabList>
                    <Tab>Overview</Tab>
                    <Tab>Mechanism of Action</Tab>
                    <Tab>Pre Clinical Results</Tab>
                    <Tab>Intellectual Property</Tab>
                    <Tab>Pipeline</Tab>
                </TabList>
            </Tabs>
        </div>
    )
}

export default OurProduct