import React, { useEffect, useRef, useState } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import 'react-tabs/style/react-tabs.css';
import ctrial1 from '../../assets/ctrials1.png'
import ctrial2 from '../../assets/ctrials2.png'
import ctrial3 from '../../assets/ctrials3.png'
import ctrial4 from '../../assets/ctrials4.png'
import ctrial5 from '../../assets/ctrials5.png'
import ctrial6 from '../../assets/ctrials6.png'
import ctrial7 from '../../assets/ctrials7.png'
import ctrial8 from '../../assets/ctrials8.png'

const ClinicalTrials = ({ activeScreen }) => {
    const myRef = useRef(null);
    const executeScroll = () => myRef.current.scrollIntoView();
    const [selectedIndex, setSelectedIndex] = useState(0);

    useEffect(() => {
        switch(activeScreen) {
            default:    
                setSelectedIndex(0);
                break;
            
            case "completed": 
                setSelectedIndex(1);
                break;

            case "ongoing":
                setSelectedIndex(2);
                break;

            case "1btrial":
                setSelectedIndex(3);
                break;
        }
    }, [activeScreen]);

    return (
        <div className="subscreen" ref={myRef}>
            <div className="subscreen-title">
                <h2>Clinical Trials</h2>
            </div>
            <Tabs
            selectedIndex={selectedIndex} 
            onSelect={(index, last, e) => {
                    setSelectedIndex(index);
                    setTimeout(() => {
                        executeScroll();
                    }, 0);
                }} 
            defaultFocus={false}>
                <TabPanel>
                    <div className="subscreen-body">
                        <h3>Overview</h3>
                        <h4>CTO Clinical Trial Program -Milestones Achieved: Safety & Efficacy in 5 Phase 1 Trials</h4>
                        <h3>Tactical Therapeutics IND 107,405</h3>
                        <ol>
                            <li>
                                Phase 1 of CTO Alone in Forty-four Advanced Cancer Patients (Source: J Cancer Ther 2015, 6:322-33)
                            </li>
                            <li>
                                Phase 1B study of CTO in combination with Temodar® in 27 recurrent GBM (Cohort 1) (Source: J. Clin. Oncol, 2018, 36: 1702-9)
                            </li>
                            <li>
                                Phase 1B study of CTO in combination with Temodar® and radiation therapy for 15 newly diagnosed GBM -13/15 unresectable tumors. (Cohort 2) (Source: J. Clin. Oncol, 2018, 36: 1702-9)
                            </li>
                        </ol>
                            <h3>Investigator Initiated IND- Duke Brain Cancer Center</h3>
                        <ol start={"4"}>
                            <li>
                                Phase 1 study of CTO alone or in combination with Bevacizumab for 19 recurrent GBM post-Bev failure, Duke Cancer Center. (Desjardins, J Clin Oncol 2015, 33: ABS 2067)
                            </li>
                            <li>Phase 1 study of the combination of CTO with lomustine in 15 patients with recurrent GBM (Friedman, J. Clin Oncol 2015, 33: e13004-e)</li>
                        </ol>
                        <br />
                        <center><h3>CTO Trial Timelines</h3><img src={ctrial7} alt="CTO: Mode of Action" style={{width: "80%"}} /></center>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="subscreen-body">
                        <h3>Phase 1 of CTO Alone in Forty-four Advanced Cancer Patients</h3>
                        <h4>RESULTS: - Showed Efficacy Measured as Durable Stable Disease lasting to 14 months</h4>
                        <p>A recently completed phase I study of CTO in a refractory patient population demonstrated safety and tolerability with no dose limiting toxicities.</p>
                        <h4><b>Phase 1 Study Objectives:</b></h4>
                        <ul>
                            <li>Safety and tolerability, MTD, PK</li>
                            <li>44 patients have been treated with CTO in 8 cohorts up to 555 mg/m2</li>
                        </ul>
                        <h4><b>Safety and Tolerability:</b></h4>
                        <ul>
                            <li>Most common AEs were fatigue, nausea, vomiting, dizziness, anorexia. Grade 3 included diarrhea, fatigue, lymphopenia</li>
                            <li>MTD  was selected as 427 mg/m2</li>
                            <li>Linear pharmacokinetics, moderate interpatient variability</li>
                        </ul>
                        <h4><b>Efficacy:</b></h4>
                        <ul>
                            <li>Single-agent CTO (at 50-555mg/m2/day) showed Stable Disease lasting 3-14 mo in different tumor types- <b> renal, small cell lung, non-small-cell lung, colorectal, head & neck & ovarian </b>, & with different genomic mutations.</li>
                        </ul>
                        <center><img src={ctrial1} alt="CTO: Mode of Action" style={{width: "80%"}} /></center>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="subscreen-body">
                        <h3>CTO <u>in combination with Temodar</u> shows Proof-of-Concept in Ph 1b with 27 Recurrent sick GBM patients (Cohort 1)</h3>
                        <h4><b>Objectives</b></h4>
                        <ul>
                            <li>
                                <b>Primary:</b>
                                <ul>
                                    <li>Safety</li>
                                    <li>MTD</li>
                                    <li>RP2D of CTO + TMZ</li>
                                </ul>
                            </li>
                            <li>
                                <b>Secondary:</b>
                                <ul>
                                    <li>PK</li>
                                </ul>
                            </li>
                            <li>
                                <b>Exploratory:</b>
                                <ul>
                                    <li>Tumor response</li>
                                    <li>Synergy between CTO/TMZ</li>
                                    <li>Effect on MGMT/genomic mutations</li>
                                    <li>CAI levels in tumor tissue</li>
                                </ul>
                            </li>
                        </ul>
                        <h4><b>Design</b></h4>
                        <ul>
                            <li>3+3 design</li>
                            <li>daily escalating doses of CTO -  219mg/m2 to 812mg/m2</li>
                            <li>TMZ 150mg/m2 days 1-5/cycle 28d</li>
                            <li>Expansion Cohort: 6 - 600mg CTO</li>
                        </ul>
                        <h4><b>Results</b></h4>
                        <p>CTO+TMZ well tolerated 600 mg CTO achieved therapeutic concentration (RP2D) Crosses BBB Tumor response was 1 CR and 6 PR: 5 with unmethylated and 1 methylated MGMT, IDH1 mutant and wild type, EGFR.</p>
                        <center><b>Waterfall Plot Response in Recurrent GBM shows Unprecedented Efficacy: 6 Partial Responses & 1 Complete Response (Cohort 1)</b><img src={ctrial2} alt="CTO: Mode of Action" style={{width: "80%"}} /></center>
                        <br />
                        <center><b>CTO induced sensitivity and Efficacy in resistant recurrent GBM (Cohort1) 1</b><img src={ctrial3} alt="CTO: Mode of Action" style={{width: "80%"}} /></center>
                        <h4><b>Conclusion</b></h4>
                        <p>POC demonstrated, informed Phase 2 Study design</p>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="subscreen-body">
                        <h3>CTO <u>in combination with Temodar and chemoradiation therapy</u> shows Proof-of-Concept Efficacy in 15 Newly Diagnosed GBM (13/15 unresectable) & resistant mutations (Cohort 2) :  PFS = 15 months, OS = 28 months + not reached</h3>
                        <h4><b>Objectives</b></h4>
                        <ul>
                            <li>
                                <b>Primary:</b>
                                <ul>
                                    <li>Safety</li>
                                    <li>MTD</li>
                                    <li>RP2D of CTO + SOC- RT/TMZ-RT</li>
                                </ul>
                            </li>
                            <li>
                                <b>Secondary:</b>
                                <ul>
                                    <li>PK</li>
                                </ul>
                            </li>
                            <li>
                                <b>Exploratory:</b>
                                <ul>
                                    <li>Tumor response</li>
                                    <li>Synergy between CTO/TMZ</li>
                                    <li>Effect on MGMT/genomic mutations</li>
                                    <li>CAI levels in tumor tissue</li>
                                </ul>
                            </li>
                        </ul>
                        <h4><b>Design</b></h4>
                        <ul>
                            <li>3+3 design</li>
                            <li>daily escalating doses of CTO -  219mg/m2 to 	485mg/m2</li>
                            <li>TMZ 75mg/m2 and standard focal RT (60Gy in 2 Gy/d fraction; CTO for 4 weeks</li>
                            <li>resumed in 28- day adjuvant cycles at 150mg/m2 days 1-5 cycle; 200mg /m2 days onwards if tolerated)</li>
                        </ul>
                        <h4><b>Results</b></h4>
                        <p>CTO+TMZ+ RT  well tolerated- MTD 370mg/m2 CTO CTO Crosses BBB; Tumor response unmethylated and methylated MGMT, IDH1 mutant and wild type, EGFR ; median PFS -15 mo, med OS-28 mo not reached; 2 patients at 60 cycles with no recurrence on CTO maintenance 600mg daily</p>
                        <center><b>Overall survival 28 mos+ Newly Diagnosed GBM (n-15)- see KM Graph Scans : Top-baseline, Low- Complete Response in 10 mos</b><img src={ctrial4} alt="CTO: Mode of Action" style={{width: "80%"}} /></center>
                        <br />
                        <center><b>CTO Crossed Blood Brain Barrier : Therapeutic Levels in tumor tissue</b><img src={ctrial5} alt="CTO: Mode of Action" style={{width: "80%"}} /></center>
                        <center><b>MODE OF ACTION OF CTO- CRANIAL CAVITY</b><img src={ctrial6} alt="CTO: Mode of Action" style={{width: "80%"}} /></center>
                        <h4><b>Conclusion</b></h4>
                        <p>POC demonstrated, Designed Phase 2 Study design</p>
                    </div>
                </TabPanel>
                <TabList>
                    <Tab>Overview</Tab>
                    <Tab>Phase 1</Tab>
                    <Tab>Phase 1B - CTO in combination with Temodar</Tab>
                    <Tab>Phase 1B - CTO in combination with Temodar and chemoradiation therapy</Tab>
                </TabList>
            </Tabs>
        </div>
    )
}

export default ClinicalTrials